import { ClearButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ClearButton';
import { autocompleteThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/autocomplete';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerFormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormDateRangePicker';
import { ScreenerFormVirtualizeAutocomplete } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormVirtualizeAutocomplete';
import { Box, createTheme } from '@mui/material';
import { Control, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

const filtersConfig = [
    {
        component: ScreenerFormVirtualizeAutocomplete,
        props: {
            defaultInputLabel: 'Product',
            filterMetadataKey: 'user_management.lookup_product.lookup_id',
        },
    },
    {
        component: ScreenerFormVirtualizeAutocomplete,
        props: {
            defaultInputLabel: 'Alert type',
            filterMetadataKey: 'user_management.lookup_alert_type.lookup_id2',
        },
    },
    {
        component: ScreenerFormDateRangePicker,
        props: {
            filterMetadataKey: 'user_management.alert.alert_time',
            buttonsText: { start: 'From', end: 'To' },
        },
    },
];

type FilterJSXArgs = {
    submitHandler: () => void;
    control: Control<FieldValues>;
    filtersData: FiltersData;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    validate: (fieldName: string) => Promise<boolean | undefined>;
    onChipClearAllClickRef: React.RefObject<() => void>;
};

export const EmailMetricsFilters = ({
    submitHandler,
    control,
    filtersData,
    getValues,
    setValue,
    validate,
    onChipClearAllClickRef,
}: FilterJSXArgs) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            gap: '12px',
        }}>
        {filtersConfig.map(({ component: Component, props }, index) => (
            <Component
                key={index}
                useFormLabelWithContainer={false}
                alwaysShowLabel={true}
                highlightOnSelection={true}
                control={control}
                filtersData={filtersData}
                submitHandler={submitHandler}
                getValues={getValues}
                setValue={setValue}
                validate={validate}
                parentSectionKey='root'
                enableCount={false}
                hide={false}
                theme={createTheme(autocompleteThemeVariant1)}
                {...props}
            />
        ))}
        <ClearButton onClick={() => onChipClearAllClickRef.current?.()} />
    </Box>
);
