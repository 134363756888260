import { useAuthenticator } from '@aws-amplify/ui-react';
import { Fallback } from '@cfra-nextgen-frontend/shared';
import { ContentWrapper } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeader';
import { ProjectSpecificResourcesContextProvider } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import ScrollToTop, { ScrollingContextProvider } from '@cfra-nextgen-frontend/shared/src/utils/scrolling';
import { exportAgGrid, exportSSRAgGrid } from 'components/excelExport/export';
import {
    getFiltersData,
    getScreenerData,
    operateEntity,
    operateMultipleEntities,
    sendAlert,
} from 'components/screener/api/screener';
import { getDataSource, getSsrDataExportFn } from 'components/screener/ssr';
import { AccountProfile, ManageGroup } from 'features/accounts';
import { ManageAccounts } from 'features/accounts/components/ManageAccounts';
import { EmailMetricsWrapper } from 'features/alerts/components/EmailMetrics';
import { Login } from 'features/auth/components/Login';
import { HomeRoutes } from 'features/home/routes';
import { ManageUsers } from 'features/users/components/ManageUsers';
import { UserProfile } from 'features/users/components/UserProfile';
import { ManagePackages } from 'features/packages';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { sendSingleRequest } from 'utils/api';
import { AppRoutePaths } from 'utils/enums';
import AuthenticatedRoutesWrapper from './AuthenticatedRoutesWrapper';
import { PackageProfile } from 'features/packages/components/PackageProfile';

const contentWrapperProps = {
    outerContainerStyles: { minHeight: 'unset' },
    innerContainerPaddingBottom: 0,
};

const routes = [
    { path: AppRoutePaths.Home, element: <HomeRoutes /> },
    { path: AppRoutePaths.ManageAccounts, element: <ManageAccounts />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.ManageUsers, element: <ManageUsers />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.Account, element: <AccountProfile />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.User, element: <UserProfile />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.Package, element: <PackageProfile />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.ManagePackages, element: <ManagePackages />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.EmailMetrics, element: <EmailMetricsWrapper />, Wrapper: ContentWrapper },
    { path: AppRoutePaths.Group, element: <ManageGroup />, Wrapper: ContentWrapper },
];

export function getRoutes(userName?: string) {
    return (
        <Route
            element={
                <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
                    <ScrollingContextProvider>
                        <ScrollToTop />
                        <ProjectSpecificResourcesContextProvider
                            projectSpecificResources={{
                                getScreenerData,
                                getFiltersData,
                                getSsrDataExportFn,
                                getDataSource,
                                exportSSRAgGrid,
                                exportAgGrid,
                                operateEntity,
                                operateMultipleEntities,
                                sendAlert,
                                sendSingleRequest,
                                userId: userName,
                            }}>
                            <AuthenticatedRoutesWrapper />
                        </ProjectSpecificResourcesContextProvider>
                    </ScrollingContextProvider>
                </ErrorBoundary>
            }
            children={routes.map((route) => {
                const { path, element, Wrapper } = route;
                return (
                    <Route
                        key={path}
                        path={path}
                        element={Wrapper ? <Wrapper {...contentWrapperProps}>{element}</Wrapper> : element}
                    />
                );
            })}
        />
    );
}

export const AppRoutes = () => {
    const { route, user } = useAuthenticator((context) => [context.route, context.user]);

    const routesJsx = useMemo(() => getRoutes(user?.username), [user?.username]);

    const browserRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(routesJsx));
    }, [routesJsx]);

    if (route !== 'authenticated' || user?.username === undefined) {
        return <Login />;
    }

    return <RouterProvider router={browserRouter} />;
};
